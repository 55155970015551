import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeContext } from '@common/contexts/ThemeStore';
import themes from '@theme';

const Theme = ({ children }) => {
	const { theme } = useContext(ThemeContext); // get the current theme ('light' or 'dark')

	const t = { ...themes };

	t['base']['primary'] =
		theme?.primaryColor?.hex || themes['base']['primary'];

	t['base']['shadow'] = theme?.shadowColor?.hex || themes['base']['shadow'];

	t['base']['component'] =
		theme?.componentBackground?.hex || themes['base']['component'];

	t['base']['text'] = theme?.textColor?.hex || themes['base']['text'];

	t['base']['background'] =
		theme?.backgroundColor?.hex || themes['base']['background'];

	t['base']['backgroundRGB'] =
		theme?.backgroundColor?.rgb || themes['base']['backgroundRGB'];

	return <ThemeProvider theme={t}>{children}</ThemeProvider>;
};

export default Theme;
