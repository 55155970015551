import { API_ROOT } from '@config/api';
import {
	ADD_CART_ITEM,
	SET_CART_ITEMS,
	CLEAR_CART_ITEM,
	CLEAR_CART_ITEMS,
} from './actionTypes';
import request from '@common/utils/request';

const createCartItem = (data) => (dispatch) =>
	request('post', `${API_ROOT}/cartItems`, data)
		.then((response) => {
			dispatch({ type: ADD_CART_ITEM, payload: response.data.data });

			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const fetchCartItemsByCart = (cart) => (dispatch) =>
	request('get', `${API_ROOT}/cartItems/cart/${cart}`)
		.then((response) => {
			dispatch({ type: SET_CART_ITEMS, payload: response.data.data });

			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const clearCartItem = () => (dispatch) =>
	dispatch({
		type: CLEAR_CART_ITEM,
	});

const clearCartItems = () => (dispatch) =>
	dispatch({
		type: CLEAR_CART_ITEMS,
	});

export { createCartItem, fetchCartItemsByCart, clearCartItem, clearCartItems };
