import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Base from '@containers/Base';

import '@common/assets/scss/bootstrap/index.scss';
import '@assets/less/antd/index.less';
import '@common/assets/scss/default.scss';

import * as serviceWorker from './serviceWorker';

import configureStore from './store/configureStore';
import initialState from './store/initialState';

import { PersistGate } from 'redux-persist/integration/react';

import { IntlProvider } from 'react-intl';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Theme from './ThemeProvider';
import { ThemeStore } from '@common/contexts/ThemeStore';

const store = configureStore(initialState);
const locale = navigator.language.split('-')[0];

window.persistor = store.persistor;
const script = document.createElement('script');
script.src = 'https://www.google.com/recaptcha/api.js';
script.async = true;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
	'pk_test_51HmAVrJYGFiOF5Ga3gHg8H1sbavCnZzZ5ft4VzZrBwGMCOgJyyGIWicYAICHe4OC7xwN6Gj0MjHOT8Sau124EdII00CYM8Zgc5'
);

document.head.appendChild(script);
ReactDOM.render(
	<Elements stripe={stripePromise}>
		<Provider store={store.store}>
			<PersistGate loading={null} persistor={window.persistor}>
				<ThemeStore>
					<Theme>
						<IntlProvider locale={locale}>
							<Base />
						</IntlProvider>
					</Theme>
				</ThemeStore>
			</PersistGate>
		</Provider>
	</Elements>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
