import React from 'react';
import { Menu } from 'antd';
const SubMenu = Menu.SubMenu;

const MenuBuilder = (props) => {
	const { menus, ...restProps } = props;
	return (
		<Menu {...restProps}>
			{menus.map((menu) =>
				menu?.type === 'submenu' ? (
					<SubMenu title={<span>{menu.subtitle}</span>}>
						{menu?.modules?.map((module) => (
							<Menu.Item key={module.url}>
								{module.title}
							</Menu.Item>
						))}
					</SubMenu>
				) : (
					<Menu.Item key={menu?.modules[0]?.url}>
						{menu?.modules[0]?.title}
					</Menu.Item>
				)
			)}
		</Menu>
	);
};

export default MenuBuilder;
