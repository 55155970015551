import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { FOREWARD_APP_USER } from '../../../config/app';
import { getCookie } from '../../../utils/cookie';

class AuthorizedRoute extends Component {
	render() {
		const { component: Component, auth, ...rest } = this.props;

		return (
			<Route
				{...rest}
				render={(props) => {
					if (!getCookie('auth')) {
						if (process.env.REACT_APP_PRODUCT === 'user') {
							return <Redirect to="/login" />;
						} else {
							window.location.href = `${FOREWARD_APP_USER}/login?s=${window.location.href}`;
							return null;
						}
					} else {
						return <Component {...props} />;
					}
				}}
			/>
		);
	}
}

export default AuthorizedRoute;
