import { SET_CART, CLEAR_CART, REMOVE_CART } from './actionTypes';

export default (state = {}, action) => {
	switch (action.type) {
		case SET_CART:
			return action.payload;
		case CLEAR_CART:
		case REMOVE_CART:
			return {};
		default:
			return state;
	}
};
