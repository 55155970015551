import { combineReducers } from 'redux';

import { commonReducers } from '@common/resources/reducers';
import { reducers } from '@resources/reducers';

const rootReducer = combineReducers({
	...commonReducers,
	...reducers,
});

export default rootReducer;
