import {
	SET_COUNTRIES,
	SET_COUNTRY,
	CLEAR_COUNTRY,
	CLEAR_COUNTRIES,
	ADD_COUNTRY,
	UPDATE_COUNTRY,
	REMOVE_COUNTRY
} from './actionTypes';

export const country = (state = {}, action) => {
	switch (action.type) {
		case SET_COUNTRY:
			return action.payload;
		case UPDATE_COUNTRY:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload
			};
		case CLEAR_COUNTRY:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_COUNTRIES:
			return action.payload;
		case ADD_COUNTRY:
			return [...state, action.payload];
		case UPDATE_COUNTRY:
			return state.map(item => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload
				};
			});
		case REMOVE_COUNTRY:
			return state.map(item => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item
				};
			});
		case CLEAR_COUNTRIES:
			return [];
		default:
			return state;
	}
};
