import React, { useContext } from 'react';
import {
	fontFamily,
	textAlign,
	lineHeight,
	fontWeight,
	fontSize,
	letterSpacing,
	color,
	space,
	fontStyle,
	flexWrap,
	width,
	border,
	shadow,
	layout,
} from 'styled-system';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';
import { Button as AntDButton } from 'antd';

const Content = styled(AntDButton)`
    ${fontSize}
    ${fontFamily}
    ${textAlign}
    ${lineHeight}
    ${fontWeight}
    ${letterSpacing}
    ${color}
    ${space}
    ${fontStyle}
    ${flexWrap}
		${width}
		${border}
		${shadow}
		${layout}
`;

const Button = ({ className, ...restProps }) => {
	const theme = useContext(ThemeContext);
	return <Content className={className} {...theme.button} {...restProps} />;
};

export default Button;
