import { FOREWARD_ENV } from '../config/app';

export const organizationBreadcrumbs = (organization) => {
	return (
		organization && [
			{
				path: `/organizations`,
				title: 'Organization Management',
			},
			{
				path: `/organizations/${organization._id}/details`,
				title: organization.title,
			},
		]
	);
};

export const parseOrganizationId = () => {
	const location = window.location;
	switch (FOREWARD_ENV) {
		case 'development':
			return location.pathname.split('/')[1];
		case 'staging':
		case 'uat':
			return location.host.split('.')[1];
		case 'production':
			return location.host.split('.')[0];
		default:
			return false;
	}
};
