import React, { useContext, useState, useCallback } from 'react';
import {
	background,
	fontFamily,
	textAlign,
	lineHeight,
	fontWeight,
	fontSize,
	letterSpacing,
	color,
	space,
	fontStyle,
	flexWrap,
	width,
} from 'styled-system';
import { connect } from 'react-redux';
import { Box, Button, Header, Section, Nav } from '../../components';
import { Avatar, Affix, Badge, Drawer, Dropdown, List } from 'antd';
import './assets/styles.scss';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components';
import { getCookie } from '@common/utils/cookie';
import { ShoppingCartOutlined } from '@ant-design/icons';

const Navbar = ({
	headerStyle,
	logo,
	leftMenu,
	rightMenu,
	cartItems,
	history,
	organization,
	showCart = true,
}) => {
	const authCookie = getCookie('auth');
	const theme = useContext(ThemeContext);
	const [visible, setVisible] = useState(false);

	const showDrawer = useCallback(() => {
		setVisible(true);
	}, [setVisible]);

	const handleClose = useCallback(() => {
		setVisible(false);
	}, [setVisible]);

	const Bars = styled.span`
	${background}
	font-weight: bold;
	background: ${theme.base.primary};
	&:before {
		background: ${theme.base.primary};
	}
	&:after {
		background: ${theme.base.primary};
	}
		
	${fontSize}
	${fontFamily}
	${textAlign}
	${lineHeight}
	${fontWeight}
	${letterSpacing}
	${color}
	${space}
	${fontStyle}
	${flexWrap}
	${width}
`;

	const menu = (
		<Section
			p={20}
			width={300}
			shadowed
			backgroundColor={theme.base.background}>
			{cartItems && cartItems.length > 0 ? (
				<List
					itemLayout="horizontal"
					dataSource={cartItems}
					renderItem={(item) => {
						const price = organization?.isMember
							? item?.product?.price?.member
								? item?.product?.price?.member
								: item?.product?.price?.basic
								? item?.product?.price?.basic
								: item?.product?.price
							: item?.product?.price?.basic
							? item?.product?.price?.basic
							: item?.product?.price;
						return (
							<List.Item>
								<List.Item.Meta
									avatar={
										<Avatar
											src={item?.product?.thumbnail?.path}
										/>
									}
									title={
										<a href="https://ant.design">
											{item?.product?.name}
										</a>
									}
									description={item?.product?.description}
								/>
								<Box p={10}>{item?.quantity}</Box>
								<Box p={10}>
									{item?.product?.currency?.symbol}
									{price}
								</Box>
							</List.Item>
						);
					}}
				/>
			) : (
				`Cart is empty`
			)}
			<Button
				type="primary"
				onClick={() => history.push(`/${organization?.alias}/cart`)}>
				View my shopping cart
			</Button>
		</Section>
	);

	return (
		<Affix>
			<Header p={20} {...headerStyle} className="fn-navbar">
				<Box floatLeft width={150} height={50} p={10}>
					{logo}
				</Box>
				<Nav className="fn-navbar-menu">
					<Box
						floatLeft
						width={[
							`calc(100% - 150px)`,
							`calc(100% - ${authCookie ? '150px' : '220px'})`,
						]}
						display={['none', 'block']}>
						{leftMenu}
					</Box>

					<Box floatRight display={['none', 'block']}>
						{rightMenu}
					</Box>
					<Box floatRight display={['block', 'none']}>
						<Button onClick={showDrawer} border={0}>
							<Bars className="bars-button"></Bars>
						</Button>
					</Box>
					{showCart && (
						<Box floatRight pt={10}>
							<Dropdown overlay={menu}>
								<Badge count={cartItems?.length || 0}>
									<Avatar
										shape="square"
										src={
											<ShoppingCartOutlined
												style={{
													color: theme.base.text,
													fontSize: 30,
												}}
											/>
										}
										className="icon icon-notification"
									/>
								</Badge>
							</Dropdown>
						</Box>
					)}
					<Drawer
						placement="right"
						closable={false}
						onClose={handleClose}
						visible={visible}>
						{leftMenu}
						{rightMenu}
					</Drawer>
				</Nav>
			</Header>
		</Affix>
	);
};

const mapStateToProps = ({ auth, cartItems, organization }) => {
	return {
		auth,
		cartItems,
		organization,
	};
};

export default connect(
	mapStateToProps,
	{}
)(Navbar);
