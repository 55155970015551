import { API_ROOT } from '@config/api';
import { SET_SITE_THEME } from './actionTypes';
import request from '@common/utils/request';

const fetchSiteTheme = (organization) => (dispatch) => {
	request('get', `${API_ROOT}/siteThemes/${organization}`)
		.then((response) => {
			dispatch({
				type: SET_SITE_THEME,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});
};

const setSiteTheme = (data) => (dispatch) => {
	if (data) {
		dispatch({ type: SET_SITE_THEME, payload: data });
	}

	return Promise.resolve(data);
};

export { fetchSiteTheme, setSiteTheme };
