import { API_ROOT } from '@config/api';
import { SET_SUBSCRIPTIONS } from './actionTypes';
import request from '@common/utils/request';

const fetchSubscriptions = (type) => (dispatch) =>
	request('get', `${API_ROOT}/subscriptions`)
		.then((response) => {
			dispatch({
				type: SET_SUBSCRIPTIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

export { fetchSubscriptions };
