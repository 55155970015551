import React, { useContext } from 'react';
import {
	fontFamily,
	textAlign,
	lineHeight,
	fontWeight,
	fontSize,
	letterSpacing,
	color,
	space,
	fontStyle,
	flexWrap,
	width,
	border,
	shadow,
	layout,
} from 'styled-system';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';

const Content = styled.div`
    ${fontSize}
    ${fontFamily}
    ${textAlign}
    ${lineHeight}
    ${fontWeight}
    ${letterSpacing}
    ${color}
    ${space}
    ${fontStyle}
    ${flexWrap}
		${width}
		${border}
		${shadow}
		${layout}
`;

const HTML = ({ className, children, ...restProps }) => {
	const theme = useContext(ThemeContext);
	return (
		<Content
			{...theme.html}
			dangerouslySetInnerHTML={{ __html: children }}
			{...restProps}
		/>
	);
};

export default HTML;
