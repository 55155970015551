import React, { useEffect } from 'react';
import { notification } from 'antd';
import Pusher from 'react-pusher';
import { connect } from 'react-redux';
import { SubscribeClick, SubscribeRefresh } from '@fragments/Subscription';
import { fetchSubscriptions } from '@resources/Subscription/actions';

const Subscription = ({
	subscriptions,
	SubscribeClick,
	SubscribeRefresh,
	fetchSubscriptions,
}) => {
	useEffect(() => {
		const fetchData = async () => {
			await fetchSubscriptions();
		};

		fetchData();
	}, [fetchSubscriptions]);

	const handleNotification = (noti) => {
		const { type, title, message, status } = noti;
		if (type && title && message) {
			SubscribeRefresh(noti);
			notification[type]({
				message: title,
				description: message,
				onClick: () => SubscribeClick(noti),
				status,
			});
		}
	};

	return (
		subscriptions &&
		subscriptions.map((subscription) => (
			<Pusher
				channel={subscription.channel}
				event={subscription.event}
				onUpdate={handleNotification.bind(this)}
			/>
		))
	);
};

const mapStateToProps = ({ subscriptions }) => {
	return {
		subscriptions,
	};
};

export default connect(mapStateToProps, {
	SubscribeClick,
	SubscribeRefresh,
	fetchSubscriptions,
})(Subscription);
