export const setCookie = (
	key,
	value,
	days = 365,
	domain = window.location.hostname
) => {
	let dom = domain;
	if (dom !== 'localhost') {
		if (domain.indexOf(':') > -1) {
			dom = domain.split(':')[0];
		} else {
			const doms = domain.split('.');
			dom = `.${doms[doms.length - 2]}.${doms[doms.length - 1]}`;
		}
	}

	const d = new Date();
	d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
	document.cookie = `${key}=${value};path=/;expires=${d.toGMTString()}${
		dom ? `;domain=${dom}` : ''
	}`;
};
export const deleteCookie = (key) => setCookie(key, '', -1);

export const getCookie = (key) => {
	var name = key + '=';
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
};
