import { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { fetchAuth } from '../../resources/Auth/actions';
import { fetchCart } from '../../resources/Cart/actions';
import { fetchCartItemsByCart } from '../../resources/CartItem/actions';
import { fetchSiteTheme } from '../../resources/SiteTheme/actions';
import {
	fetchOrganization,
	clearOrganization,
} from '../../resources/Organization/actions';
import { fetchUser, clearUser } from '../../resources/User/actions';
import { getCookie } from '@common/utils/cookie';
import { parseOrganizationId } from '@common/utils/organization';
import { parseUserId } from '@common/utils/user';
import isEmpty from 'lodash.isempty';
import { ThemeContext } from '../../contexts/ThemeStore';
import { ThemeContext as StyledThemeContext } from 'styled-components';

const Middleware = ({
	cart,
	clearOrganization,
	fetchAuth,
	siteTheme,
	organization,
	children,
	fetchCart,
	fetchCartItemsByCart,
	fetchOrganization,
	fetchSiteTheme,
	fetchUser,
	location,
	user,
}) => {
	const authCookie = getCookie('auth');
	const [loading, setLoading] = useState(false);
	const [org, setOrg] = useState(null);
	const [usr, setUsr] = useState(null);
	const { updateTheme } = useContext(ThemeContext);
	const theme = useContext(StyledThemeContext);
	useEffect(() => {
		// setLoading(true);
		const fetchData = async () => {
			await fetchAuth();
			await fetchCart();
		};

		!isEmpty(authCookie) ? fetchData() : setLoading(false);
	}, [authCookie]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				await fetchCartItemsByCart(cart?._id);
			} catch (e) {}
		};

		!isEmpty(cart) && fetchData();
	}, [cart]);

	useEffect(() => {
		const fetchOrg = async () => {
			const orgId = parseOrganizationId();
			try {
				await fetchOrganization(orgId);
			} catch (e) {
				await clearOrganization();
			}
			setOrg(orgId);
		};

		const fetchUsr = async () => {
			const usrId = parseOrganizationId();
			try {
				await fetchUser(usrId);
			} catch (e) {
				await clearUser();
			}
			setUsr(usrId);
		};

		process.env.REACT_APP_PRODUCT === 'organization' &&
			org !== parseOrganizationId() &&
			fetchOrg();

		process.env.REACT_APP_PRODUCT === 'user' &&
			usr !== parseUserId() &&
			fetchUsr();
	}, [location]);

	useEffect(() => {
		const fetchData = async () => {
			await fetchSiteTheme(organization?._id);
		};

		fetchData();

		setLoading(false);
	}, [organization, user]);

	useEffect(() => {
		if (!isEmpty(siteTheme)) {
			updateTheme(siteTheme);
			window.less
				.modifyVars({
					'@primary-color':
						siteTheme?.primaryColor?.hex || theme.base.primary,
					'@normal-color': '#d9d9d9',
					'@text-color': siteTheme?.textColor?.hex || '#FFFFFF',
					'@text-color-secondary':
						siteTheme?.textColor?.hex || '#FFFFFF',
					'@heading-color':
						siteTheme?.textColor?.hex || 'fade(@white, 85%)',
					'@body-background':
						siteTheme?.backgroundColor?.hex || '#333333',
					'@select-item-selected-bg':
						siteTheme?.backgroundColor?.hex || '#333333',
					'@background-color-base':
						siteTheme?.backgroundColor?.hex || '#333333',
					'@background-color-light':
						siteTheme?.componentBackground?.hex || '#444',
					'@component-background':
						siteTheme?.componentBackground?.hex || '#000',
					'@checkbox-text-color':
						siteTheme?.textColor?.hex || '#FFFFFF',
					'@disabled-color':
						siteTheme?.componentBackground?.hex ||
						'rgba(255, 255, 255, 255, 0.25)',
					'@divider-color':
						siteTheme?.componentBackground?.hex || '#555',
					'@rate-star-bg':
						siteTheme?.componentBackground?.hex || '#555',
				})
				.then(() => {});
		} else {
			updateTheme({
				primaryColor: { hex: theme.base.primary },
				textColor: { hex: '#FFFFFF' },
				componentBackground: { hex: '#555' },
			});
			window.less
				.modifyVars({
					'@primary-color': theme.base.primary,
					'@secondary-color': '#c1d8de',
					'@normal-color': '#d9d9d9',
					'@text-color': '#FFFFFF',
					'@text-color-secondary': '#FFFFFF',
					'@heading-color': 'fade(@white, 85%)',
					'@body-background': '#333',
					'@select-item-selected-bg': '#333',
					'@background-color-base': '#333',
					'@background-color-light': '#444',
					'@component-background': '#555',
					'@select-item-active-bg': '#aaa',
					'@checkbox-text-color': '#FFFFFF',
					'@disabled-color': 'rgba(255, 255, 255, 255, 0.25)',
					'@divider-color': '#555',
					'@rate-star-bg': '#555',
				})
				.then(() => {
					console.log('update');
				});
		}
	}, [siteTheme]);

	console.log(loading);

	return loading ? null : children;
};

const mapStateToProps = ({ auth, cart, organization, user, siteTheme }) => ({
	auth,
	cart,
	organization,
	siteTheme,
	user,
});

export default connect(
	mapStateToProps,
	{
		clearOrganization,
		fetchAuth,
		fetchCart,
		fetchOrganization,
		fetchSiteTheme,
		fetchUser,
		fetchCartItemsByCart,
	}
)(Middleware);
