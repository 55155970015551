import Pusher from 'pusher-js';
import { FOREWARD_PUSHER_KEY, FOREWARD_PUSHER_CLUSTER } from '../config/app';

const pusher = new Pusher(FOREWARD_PUSHER_KEY, {
	cluster: FOREWARD_PUSHER_CLUSTER,
	encrypted: true,
});

let channels = [];
let pusherChannels = [];
let evs = [];

export const subscribe = ({ channel, events }) => {
	let chan = null;
	if (channels.indexOf(channel) === -1) {
		chan = pusher.subscribe(channel);
		pusherChannels.push(chan);
		channels.push(chan);
	} else {
		chan = pusherChannels[channels.indexOf(channel)];
	}

	if (chan) {
		events.forEach(({ id, func }) => {
			chan.bind(id, func);
			if (
				evs.filter((ev) => ev.channel === channel && ev.event === id)
					.length === 0
			) {
				evs.push({ channel, event: id });
			}
		});
	}
};

export const unsubscribe = ({ channel }) => {
	const idx = channels.indexOf(channel);
	if (idx > -1) {
		evs.filter((ev) => ev.channel === channel).forEach((event) => {
			pusherChannels[channels.indexOf(channel)].unbind(event.id);
		});

		pusher.unsubscribe(channel);
		channel.splice(idx, 1);
	}
};
