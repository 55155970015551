import { SET_SITE_THEME } from './actionTypes';

export default (state = null, action) => {
	switch (action.type) {
		case SET_SITE_THEME:
			return action.payload;
		default:
			return state;
	}
};
