import React, { useContext } from 'react';
import {
	flex,
	justifySelf,
	alignSelf,
	order,
	display,
	maxWidth,
	minWidth,
	width,
	height,
	maxHeight,
	minHeight,
	position,
	size,
	space,
} from 'styled-system';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';

const BaseImage = styled.img`
  ${space}
  ${flex}
  ${justifySelf}
  ${alignSelf}
  ${display}
  ${width}
  ${maxWidth}
  ${minWidth}
  ${position}
  ${height}
  ${maxHeight}
  ${minHeight}
  ${size}
  ${order}
`;

const Image = ({ className, ...restProps }) => {
	const theme = useContext(ThemeContext);
	return <BaseImage {...theme.image} {...restProps} />;
};

export default Image;
