let analytics_id,
	google_api_key,
	pusher_id,
	pusher_key,
	pusher_cluster,
	app_page,
	app_user,
	app_admin,
	app_church,
	app_organization,
	env;

const hostname = window && window.location && window.location.hostname;
let subdomain = hostname.split('.')[0];

analytics_id = 'UA-142685791-1';
google_api_key = 'AIzaSyDA_MG_I8yfXcseN6RfREhsC9Gt7BSBE9E';

const DOMAIN = 'foreward.net';

const APP_USER = `user.${DOMAIN}`;
const APP_ADMIN = `admin.${DOMAIN}`;
const APP_PAGE = `page.${DOMAIN}`;
const APP_CHURCH = `church.${DOMAIN}`;
const APP_ORGANIZATION = `organization.${DOMAIN}`;

if (subdomain === `staging`) {
	pusher_id = '810978';
	pusher_key = '1d6e41a82ac1606f6779';
	pusher_cluster = 'ap1';

	app_admin = `https://staging.${APP_ADMIN}`;
	app_user = `https://staging.${APP_USER}`;
	app_page = `https://staging.${APP_PAGE}`;
	app_church = `https://staging.${APP_CHURCH}`;
	app_organization = `https://staging.${APP_ORGANIZATION}`;

	env = 'stating';
} else if (subdomain === `uat`) {
	pusher_id = '810978';
	pusher_key = '1d6e41a82ac1606f6779';
	pusher_cluster = 'ap1';

	app_admin = `https://uat.${APP_ADMIN}`;
	app_user = `https://uat.${APP_USER}`;
	app_page = `https://uat.${APP_PAGE}`;
	app_church = `https://uat.${APP_CHURCH}`;
	app_organization = `https://uat.${APP_ORGANIZATION}`;

	env = 'uat';
} else if (
	subdomain === process.env.REACT_APP_PRODUCT &&
	hostname !== 'localhost'
) {
	pusher_id = '810979';
	pusher_key = 'c1de835885a0939d9272';
	pusher_cluster = 'ap1';

	app_admin = `https://${APP_ADMIN}`;
	app_user = `https://${APP_USER}`;
	app_page = `https://${APP_PAGE}`;
	app_church = `https://${APP_CHURCH}`;
	app_organization = `https://${APP_ORGANIZATION}`;

	env = 'production';
} else if (/^qa/.test(hostname)) {
	pusher_id = '810977';
	pusher_key = '42689551388674d18f10';
	pusher_cluster = 'ap1';

	app_admin = `http://localhost:9000`;
	app_user = `http://localhost:6010`;
	app_page = `http://localhost:6020`;
	app_church = `http://localhost:6030`;
	app_organization = `https://localhost:6040`;

	env = 'development';
} else {
	pusher_id = '810977';
	pusher_key = '42689551388674d18f10';
	pusher_cluster = 'ap1';

	app_admin = `http://localhost:9000`;
	app_user = `http://localhost:6010`;
	app_page = `http://localhost:6020`;
	app_church = `http://localhost:6030`;
	app_organization = `http://localhost:6040`;

	env = 'development';
}

export const FOREWARD_GOOGLE_ANALYTICS_ID = analytics_id;
export const FOREWARD_GOOGLE_API_KEY = google_api_key;

export const FOREWARD_PUSHER_ID = pusher_id;
export const FOREWARD_PUSHER_KEY = pusher_key;
export const FOREWARD_PUSHER_CLUSTER = pusher_cluster;

export const FOREWARD_APP_ADMIN = app_admin;
export const FOREWARD_APP_USER = app_user;
export const FOREWARD_APP_PAGE = app_page;
export const FOREWARD_APP_CHURCH = app_church;
export const FOREWARD_APP_ORGANIZATION = app_organization;

export const FOREWARD_ENV = env;
