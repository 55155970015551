// import React, { Component } from 'react';
// import { notification } from 'antd';
// import Pusher from 'react-pusher';

const Notifications = () => {
	// const handleNotification = (noti) => {
	// 	const { type, title, message, status, onClick } = noti;
	// 	if (type && title && message) {
	// 		// notification[type]({
	// 		// 	message: title,
	// 		// 	description: message,
	// 		// 	onClick: onClick,
	// 		// 	status,
	// 		// });
	// 	}
	// };

	return null;
};

export default Notifications;
