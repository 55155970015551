import { useCallback } from 'react';
import { connect } from 'react-redux';

const RightMenu = (props) => {
	const { auth, location } = props;

	const menus = useCallback((menuProps) => null, [auth]);

	if (window.matchMedia('(min-width: 650px)').matches) {
		return menus({ mode: 'horizontal', selectedKeys: [location.pathname] });
	} else {
		return menus({
			mode: 'inline',
			selectedKeys: [location.pathname],
			defaultOpenKeys: ['setting'],
			onClick: () => {},
		});
	}
};

const mapStateToProps = ({ auth, cartItems }) => {
	return {
		auth,
		cartItems,
	};
};

export default connect(
	mapStateToProps,
	{}
)(RightMenu);
