import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { FOREWARD_GOOGLE_ANALYTICS_ID } from '../../config/app';

ReactGA.initialize(FOREWARD_GOOGLE_ANALYTICS_ID, { testMode: true });
let mounted = false;

const Analytics = ({ history }) => {
	useEffect(() => {
		ReactGA.set({ page: history.location.pathname });
		ReactGA.pageview(history.location.pathname);
		mounted = true;
	}, []);

	if (!mounted) {
		history.listen(() => {
			// view new URL
			ReactGA.set({ page: history.location.pathname });
			ReactGA.pageview(history.location.pathname);
		});
	}

	return null;
};

export default withRouter(Analytics);
