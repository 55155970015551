import {
	SET_SUBSCRIPTIONS,
	SET_SUBSCRIPTION,
	CLEAR_SUBSCRIPTIONS,
	CLEAR_SUBSCRIPTION,
	ADD_SUBSCRIPTION,
	UPDATE_SUBSCRIPTION,
	REMOVE_SUBSCRIPTION,
} from './actionTypes';

export const subscription = (state = {}, action) => {
	switch (action.type) {
		case SET_SUBSCRIPTION:
			return action.payload;
		case UPDATE_SUBSCRIPTION:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_SUBSCRIPTION:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_SUBSCRIPTIONS:
			return action.payload;
		case ADD_SUBSCRIPTION:
			return [...state, action.payload];
		case UPDATE_SUBSCRIPTION:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_SUBSCRIPTION:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_SUBSCRIPTIONS:
			return [];
		default:
			return state;
	}
};
