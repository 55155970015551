import {
	SET_CITIES,
	SET_CITY,
	CLEAR_CITY,
	CLEAR_CITIES,
	ADD_CITY,
	UPDATE_CITY,
	REMOVE_CITY
} from './actionTypes';

export const city = (state = {}, action) => {
	switch (action.type) {
		case SET_CITY:
			return action.payload;
		case UPDATE_CITY:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload
			};
		case CLEAR_CITY:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_CITIES:
			return action.payload;
		case ADD_CITY:
			return [...state, action.payload];
		case UPDATE_CITY:
			return state.map(item => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload
				};
			});
		case REMOVE_CITY:
			return state.map(item => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item
				};
			});
		case CLEAR_CITIES:
			return [];
		default:
			return state;
	}
};
