import axios from 'axios';

import { getCookie } from './cookie';
import { APP_ROOT } from '@config/app';

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const { response } = error;

		// Logout application if status is 401
		if (
			response &&
			response.status === 401 &&
			window.location.href !== `${APP_ROOT}/login`
		) {
			window.location.href = `${APP_ROOT}/logout`;
		}

		if (
			response &&
			response.status === 503 &&
			window.location.pathname !== '/maintenance'
		) {
			window.location.href = '/maintenance';
		}

		return Promise.reject(error);
	}
);

export default (method, url, data, config) => {
	const token = getCookie('auth');

	if (token) {
		axios.defaults.headers.common['authorization'] = `${token}`;
	}

	if (['get', 'delete'].indexOf(method) >= 0) {
		// Add request params if method is get
		data = Object.assign({}, data, config);
		return axios[method](url, data);
	}

	return axios[method](url, data, config);
};
