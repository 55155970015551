import { useEffect } from 'react';

import { connect } from 'react-redux';
import { subscribe } from '../../utils/socket';

const WebSocket = ({ subscriptions }) => {
	useEffect(() => {
		subscriptions &&
			subscriptions.forEach((subscription) => {
				subscribe(subscription);
			});
	}, [subscriptions]);
	return null;
};

const mapStateToProps = (state) => {
	return {
		subscriptions: state.subscriptions,
	};
};

export default connect(mapStateToProps)(WebSocket);
