import { API_ROOT } from '@config/api';
import { SET_AUTH, REMOVE_AUTH, CLEAR_AUTH } from './actionTypes';
import request from '@common/utils/request';

const fetchAuth = () => (dispatch) =>
	request('get', `${API_ROOT}/auth`)
		.then((response) => {
			dispatch({ type: SET_AUTH, payload: response.data.data });

			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const doLogout = () => (dispatch) =>
	request('get', `${API_ROOT}/logout`)
		.then((response) => {
			dispatch({ type: REMOVE_AUTH });

			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const fetchProfile = () => (dispatch) =>
	request('get', `${API_ROOT}/user`)
		.then((response) => {
			dispatch({ type: SET_AUTH });

			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const clearAuth = () => (dispatch) => dispatch({ type: CLEAR_AUTH });

export { fetchAuth, doLogout, fetchProfile, clearAuth };
