export default {
	colors: {
		primary: '#0ad4f2',
	},
	primary: '#0ad4f2',
	shadow: '#222',
	component: '#555',
	text: '#000',
	background: '#333',
	backgroundRBG: { r: 51, g: 51, b: 51 },
};
