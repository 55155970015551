import React, { useContext } from 'react';
import {
	fontFamily,
	textAlign,
	lineHeight,
	fontWeight,
	fontSize,
	letterSpacing,
	color,
	space,
	fontStyle,
	flexWrap,
	width,
} from 'styled-system';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';
import './assets/container.scss';

const Content = styled.div`
    ${fontSize}
    ${fontFamily}
    ${textAlign}
    ${lineHeight}
    ${fontWeight}
    ${letterSpacing}
    ${color}
    ${space}
    ${fontStyle}
    ${flexWrap}
    ${width}
`;

const Container = ({ className, children, centralised, ...restProps }) => {
	const theme = useContext(ThemeContext);
	return (
		<Content
			className={`fn-container ${
				centralised ? 'container-centralised' : 'container'
			} ${className || ''}`}
			{...theme.container}
			{...restProps}>
			{centralised ? (
				<div className="container-centralised-content">{children}</div>
			) : (
				children
			)}
		</Content>
	);
};

export default Container;
