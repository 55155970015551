import {
	SET_LANGUAGES,
	SET_LANGUAGE,
	CLEAR_LANGUAGE,
	CLEAR_LANGUAGES,
	ADD_LANGUAGE,
	UPDATE_LANGUAGE,
	REMOVE_LANGUAGE
} from './actionTypes';

export const language = (state = {}, action) => {
	switch (action.type) {
		case SET_LANGUAGE:
			return action.payload;
		case UPDATE_LANGUAGE:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload
			};
		case CLEAR_LANGUAGE:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_LANGUAGES:
			return action.payload;
		case ADD_LANGUAGE:
			return [...state, action.payload];
		case UPDATE_LANGUAGE:
			return state.map(item => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload
				};
			});
		case REMOVE_LANGUAGE:
			return state.map(item => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item
				};
			});
		case CLEAR_LANGUAGES:
			return [];
		default:
			return state;
	}
};
