import { API_ROOT } from '@config/api';
import request from '@common/utils/request';
import { SET_USER, SET_USERS, CLEAR_USER, CLEAR_USERS } from './actionTypes';

const fetchUsersMembers = (filters, limit) => (dispatch) =>
	request(
		'get',
		`${API_ROOT}/users/members?${
			filters ? `&filters=${JSON.stringify(filters)}` : ''
		}${limit ? `&limit=${limit}` : ''}`
	)
		.then((response) => {
			dispatch({
				type: SET_USERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const fetchUser = (id) => (dispatch) =>
	request('get', `${API_ROOT}/users/${id}`)
		.then((response) => {
			dispatch({
				type: SET_USER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createUser = (data) => () =>
	request('post', `${API_ROOT}/users`, data)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const checkUserInvite = (data) => (dispatch) =>
	request('post', `${API_ROOT}/users/invite/check`, data)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const createUserInvite = (data) => (dispatch) =>
	request('post', `${API_ROOT}/users/invite/create`, data)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const clearUser = () => (dispatch) =>
	dispatch({
		type: CLEAR_USER,
	});

const clearUsers = () => (dispatch) =>
	dispatch({
		type: CLEAR_USERS,
	});

export {
	fetchUsersMembers,
	fetchUser,
	createUser,
	checkUserInvite,
	createUserInvite,
	clearUser,
	clearUsers,
};
