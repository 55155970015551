import React from 'react';
import {
	border,
	fontFamily,
	textAlign,
	lineHeight,
	fontWeight,
	fontSize,
	letterSpacing,
	color,
	space,
	fontStyle,
	flexWrap,
	width,
	layout,
	flexbox,
} from 'styled-system';
import styled from 'styled-components';

const Wrapper = styled.div`
	${flexbox}
	${layout}
	${border}
	${fontSize}
	${fontFamily}
	${textAlign}
	${lineHeight}
	${fontWeight}
	${letterSpacing}
	${color}
	${space}
	${fontStyle}
	${flexWrap}
	${width}
`;

const View = ({ children, ...restProps }) => {
	return (
		<Wrapper className="fn-view" {...restProps}>
			<div className="fn-content">
				<div className="fn-content--inner">{children}</div>
			</div>
		</Wrapper>
	);
};

export default View;
