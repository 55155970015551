// @flow
import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import reorder, { move } from './utils/reorder';
import { grid } from './utils/constants';
import { Typography } from 'antd';
import Section from '../Section';
import { ThemeContext } from 'styled-components';

import styled, { css } from 'styled-components';

const portal = document.createElement('div');
portal.classList.add('my-super-cool-portal');

if (!document.body) {
	throw new Error('body not ready for portal creation!');
}

document.body.appendChild(portal);

const DraggableItem = styled.div`
	padding: ${grid}px;
	border-radius: 10px;
	margin-bottom: ${grid}px;
	background-color: #555;
	border: 1px solid #fff;
	/* used for positioning the after content */
	position: relative;
	/* stylelint-disable  comment-empty-line-before */
	/* add little portal indicator when in a portal */
	${(props) =>
		props.inPortal
			? `
    ::after {
      position: absolute;
      background: lightgreen;
      padding: ${grid}px;
      bottom: 0;
      right: 0;
      content: "in portal";
    }
  `
			: ''} /* stylelint-enable */;
	${(props) => {
		const theme = useContext(ThemeContext);
		console.log(theme);
		return css`
			background-color: ${theme.base.component};
		`;
	}}
`;

const BoardContainer = styled.div`
	display: flex;
	width: 100%;
	overflow-x: auto;
`;

const PortalItem = ({ provided, snapshot, item, listItem, ...restProps }) => {
	const usePortal = snapshot.isDragging;
	const child = (
		<DraggableItem
			ref={provided.innerRef}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
			inPortal={usePortal}>
			{listItem({ item, ...restProps })}
		</DraggableItem>
	);

	if (!usePortal) {
		return child;
	}

	// if dragging - put the item in a portal
	return ReactDOM.createPortal(child, portal);
};

const DraggableContainer = styled.div`
	margin: 0 auto;
	width: 300px;
	min-height: 20px;
`;

const Board = ({ initial, onReorder, listItem, ...restProps }) => {
	const [data, setData] = useState(initial);

	useEffect(() => {
		setData(initial);
	}, [initial]);

	const getList = (id) => data[id];

	const onDragEnd = (result) => {
		const { source, destination } = result;
		// dropped outside the list
		if (!destination) {
			return;
		}

		let newOrder = {};

		if (source.droppableId === destination.droppableId) {
			const items = reorder(
				getList(source.droppableId),
				source.index,
				destination.index
			);

			newOrder = { ...data, [source.droppableId]: items };
		} else {
			const moved = move(
				getList(source.droppableId),
				getList(destination.droppableId),
				source,
				destination
			);

			newOrder = {
				...data,
				[source.droppableId]: moved[source.droppableId],
				[destination.droppableId]: moved[destination.droppableId],
			};
		}
		onReorder && onReorder(newOrder);
		setData(newOrder);
	};

	return (
		<BoardContainer>
			<DragDropContext onDragEnd={onDragEnd}>
				{Object.keys(data).map((key) => {
					return (
						<Section shadowed hoverable m={20}>
							<Typography.Title level={4}>{key}</Typography.Title>
							<Droppable droppableId={key}>
								{(droppableProvided) => (
									<DraggableContainer
										ref={droppableProvided.innerRef}
										{...droppableProvided.droppableProps}>
										{data[key].map((item, index) => (
											<Draggable
												draggableId={item.id}
												index={index}
												key={item.id}>
												{(
													draggableProvided,
													draggableSnapshot
												) => (
													<PortalItem
														listItem={listItem}
														item={item}
														provided={
															draggableProvided
														}
														snapshot={
															draggableSnapshot
														}
														{...restProps}
													/>
												)}
											</Draggable>
										))}
										{droppableProvided.placeholder}
									</DraggableContainer>
								)}
							</Droppable>
						</Section>
					);
				})}
			</DragDropContext>
		</BoardContainer>
	);
};

export default Board;
