import React, { useContext } from 'react';
import {
	background,
	fontFamily,
	textAlign,
	lineHeight,
	fontWeight,
	fontSize,
	letterSpacing,
	color,
	space,
	fontStyle,
	flexWrap,
	flexbox,
	width,
	border,
	shadow,
	layout,
	position,
} from 'styled-system';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';

const Content = styled.div`
		${(props) =>
			props.centered &&
			`
		display: flex;
		flex-direction: left;
		margin: 0;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-direction: column;
    flex-wrap: wrap;
		`}
		${(props) => props.floatLeft && `float: left;`}
		${(props) => props.floatRight && `float: right;`}

		${position}
		${flexbox}
		${background}
		${fontSize}
    ${fontFamily}
    ${textAlign}
    ${lineHeight}
    ${fontWeight}
    ${letterSpacing}
    ${color}
    ${space}
    ${fontStyle}
    ${flexWrap}
		${width}
		${border}
		${shadow}
		${layout}
`;

const Box = ({ ...restProps }) => {
	const theme = useContext(ThemeContext);
	return <Content {...theme.box} {...restProps} />;
};

export default Box;
