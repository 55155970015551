import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import { play, exit } from '@common/timelines';
import { connect } from 'react-redux';
import Landing from '@containers/Landing';
import Policy from '@containers/Policy';
import Terms from '@containers/Terms';
import {
	Analytics,
	Middleware,
	Notifications,
	Subscription,
	Navbar,
} from '@common/fragments';
import Pusher from 'pusher-js';
import {
	FOREWARD_PUSHER_KEY,
	FOREWARD_PUSHER_CLUSTER,
} from '@common/config/app';
import { Box, Image, View } from '@common/components';

import { LeftMenu, RightMenu } from '@fragments';

import ScrollToTop from '@common/fragments/ScrollToTop';
import { setPusherClient } from 'react-pusher';
import logo from '@assets/images/logo.png';
import { ThemeContext } from 'styled-components';

const pusherClient = new Pusher(FOREWARD_PUSHER_KEY, {
	cluster: FOREWARD_PUSHER_CLUSTER,
	encrypted: true,
});

setPusherClient(pusherClient);

const Base = (props) => {
	const theme = useContext(ThemeContext);

	return (
		<BrowserRouter>
			<ScrollToTop>
				<Route
					path="/"
					render={(props) => {
						let authorise = true;
						let resource = true;

						return (
							<div>
								<Middleware
									{...props}
									resource={resource}
									authorise={authorise}>
									<Route
										render={(props) => {
											const { location } = props;
											const { pathname, key } = location;

											return (
												<>
													<Navbar
														{...props}
														showCart={false}
														logo={
															<Image
																height={40}
																width="auto"
																src={logo}
															/>
														}
														leftMenu={
															<LeftMenu
																{...props}
															/>
														}
														rightMenu={
															<RightMenu
																{...props}
															/>
														}
													/>

													<TransitionGroup
														component={null}>
														<Transition
															key={key}
															appear={true}
															onEnter={(
																node,
																appears
															) => {
																if (node) {
																	return play(
																		pathname,
																		node,
																		appears
																	);
																}
															}}
															onExit={(
																node,
																appears
															) => {
																if (node) {
																	return exit(
																		node,
																		appears
																	);
																}
															}}
															timeout={{
																enter: 750,
																exit: 150,
															}}>
															<Switch
																location={
																	location
																}>
																<Route
																	exact
																	path="/"
																	component={
																		Landing
																	}
																	{...props}
																/>
																<Route
																	exact
																	path="/policy"
																	component={
																		Policy
																	}
																	{...props}
																/>
																<Route
																	exact
																	path="/privacy"
																	component={
																		Policy
																	}
																	{...props}
																/>
																<Route
																	exact
																	path="/terms"
																	component={
																		Terms
																	}
																	{...props}
																/>
															</Switch>
														</Transition>
													</TransitionGroup>

													<TransitionGroup
														component={null}>
														<Transition
															key={key}
															appear={true}
															onEnter={(
																node,
																appears
															) => {
																if (node) {
																	return play(
																		pathname,
																		node,
																		appears
																	);
																}
															}}
															onExit={(
																node,
																appears
															) => {
																if (node) {
																	return exit(
																		node,
																		appears
																	);
																}
															}}
															timeout={{
																enter: 750,
																exit: 150,
															}}>
															<View>
																<Box
																	mt={50}
																	p={50}
																	backgroundColor={
																		theme
																			.footer
																			.background
																	}
																	style={{
																		textAlign:
																			'center',
																	}}>
																	Foreward
																	Network
																	©2021
																</Box>
															</View>
														</Transition>
													</TransitionGroup>
												</>
											);
										}}
									/>
								</Middleware>
							</div>
						);
					}}
				/>
				<Route path="/" component={Analytics} {...props} />
				<Route path="/" component={Notifications} {...props} />
				<Route path="/" component={Subscription} {...props} />
			</ScrollToTop>
		</BrowserRouter>
	);
};

const mapStateToProps = ({ site, events, organization, siteTheme }) => {
	return { site, events, organization, siteTheme };
};

export default connect(mapStateToProps)(Base);
