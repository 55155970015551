import { API_ROOT } from '@config/api';
import { SET_CART, CLEAR_CART } from './actionTypes';
import request from '@common/utils/request';

const fetchCart = () => (dispatch) =>
	request('get', `${API_ROOT}/cart`)
		.then((response) => {
			dispatch({ type: SET_CART, payload: response.data.data });

			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const clearCart = () => (dispatch) =>
	dispatch({
		type: CLEAR_CART,
	});

export { fetchCart, clearCart };
