import React, { useContext } from 'react';
import {
	fontFamily,
	textAlign,
	lineHeight,
	fontWeight,
	fontSize,
	letterSpacing,
	color,
	space,
	fontStyle,
	flexWrap,
	width,
	border,
	shadow,
} from 'styled-system';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';

const Content = styled.div`
		width: calc(100% - 150px);
		float: right;
    ${fontSize}
    ${fontFamily}
    ${textAlign}
    ${lineHeight}
    ${fontWeight}
    ${letterSpacing}
    ${color}
    ${space}
    ${fontStyle}
    ${flexWrap}
		${width}
		${border}
		${shadow}
`;

const Nav = ({ ...restProps }) => {
	const theme = useContext(ThemeContext);
	return <Content {...theme.nav} {...restProps} />;
};

export default Nav;
