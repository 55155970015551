import React, { useCallback } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { useIntl } from 'react-intl';
import { getCookie } from '@common/utils/cookie';
import MenuBuilder from './MenuBuilder';
const SubMenu = Menu.SubMenu;

const LeftMenu = (props) => {
	const { auth, history, organization } = props;
	const intl = useIntl();
	const authCookie = getCookie('auth');
	const apps = organization?.apps?.map((app) => app.alias) || [];

	const handleClick = useCallback(
		(e) => {
			const base = organization ? `/${organization.alias}` : `/`;

			history.push(`${base}/${e.key}`);
		},
		[organization]
	);

	const menus = useCallback(
		(menuProps) => {
			if (organization) {
				if (authCookie && !isEmpty(auth)) {
					if (!organization.isAdmin && !organization.isOwner) {
						if (
							organization.isMember &&
							organization.menus.member.length > 0
						) {
							return (
								<MenuBuilder
									{...menuProps}
									onClick={handleClick}
									menus={organization.menus.member}
								/>
							);
						} else if (
							organization.isCustomer &&
							organization.menus.customer.length > 0
						) {
							return (
								<MenuBuilder
									{...menuProps}
									onClick={handleClick}
									menus={organization.menus.customer}
								/>
							);
						}
					}

					return (
						<Menu {...menuProps} onClick={handleClick}>
							{apps.includes('social') && (
								<Menu.Item key="events">
									{intl.formatMessage({
										id: 'common.events',
										defaultMessage: 'Event',
									})}
								</Menu.Item>
							)}
							{!isEmpty(organization) && organization.isAdmin && (
								<SubMenu
									key="asset"
									title={
										<span>
											{intl.formatMessage({
												id: 'common.asset',
												defaultMessage: 'Asset',
											})}
										</span>
									}>
									<Menu.Item key="pages">
										{intl.formatMessage({
											id: 'common.pages',
											defaultMessage: 'Page',
										})}
									</Menu.Item>

									<Menu.Item key="forms">
										{intl.formatMessage({
											id: 'common.forms',
											defaultMessage: 'Form',
										})}
									</Menu.Item>

									<Menu.Item key="groups">
										{intl.formatMessage({
											id: 'common.group',
											defaultMessage: 'Group',
										})}
									</Menu.Item>
								</SubMenu>
							)}

							{!isEmpty(organization) && organization.isAdmin && (
								<SubMenu
									key="organization"
									title={
										<span>
											{intl.formatMessage({
												id: 'common.organization',
												defaultMessage: 'Organization',
											})}
										</span>
									}>
									<Menu.Item key="accounts">
										{intl.formatMessage({
											id: 'common.account',
											defaultMessage: 'Account',
										})}
									</Menu.Item>
									<Menu.Item key="departments">
										Departments
									</Menu.Item>
									<Menu.Item key="positions">
										Positions
									</Menu.Item>
									<Menu.Item key="projects">
										{intl.formatMessage({
											id: 'common.project',
											defaultMessage: 'Project',
										})}
									</Menu.Item>
								</SubMenu>
							)}

							{!isEmpty(organization) && organization.isMember && (
								<SubMenu
									key="user"
									title={
										<span>
											{intl.formatMessage({
												id: 'common.users',
												defaultMessage: 'Users',
											})}
										</span>
									}>
									{!isEmpty(organization) &&
										organization.isAdmin && (
											<Menu.Item key="admins">
												Admins
											</Menu.Item>
										)}
									<Menu.Item key="members">Members</Menu.Item>
									<Menu.Item key="guests">Guests</Menu.Item>
								</SubMenu>
							)}

							{apps.includes('market') && (
								<SubMenu
									key="market"
									title={
										<span>
											{intl.formatMessage({
												id: 'common.market',
												defaultMessage: 'Market',
											})}
										</span>
									}>
									<Menu.Item key="shops">Shop</Menu.Item>
								</SubMenu>
							)}

							{apps.includes('school') && (
								<SubMenu
									key="school"
									title={
										<span>
											{intl.formatMessage({
												id: 'common.school',
												defaultMessage: 'School',
											})}
										</span>
									}>
									<Menu.Item key="classes">Class</Menu.Item>
								</SubMenu>
							)}

							{apps.includes('finance') &&
								organization.isAdmin &&
								(organization?.access?.includes('income') ||
									organization?.access?.includes(
										'expense'
									)) && (
									<SubMenu
										key="finance"
										title={
											<span>
												{intl.formatMessage({
													id: 'common.finance',
													defaultMessage: 'Finance',
												})}
											</span>
										}>
										{organization?.access?.includes(
											'income'
										) && (
											<Menu.Item key="incomes">
												Income
											</Menu.Item>
										)}
										{organization?.access?.includes(
											'expense'
										) && (
											<Menu.Item key="expenses">
												Expense
											</Menu.Item>
										)}
									</SubMenu>
								)}

							{apps.includes('logistic') && (
								<SubMenu
									key="logistic"
									title={
										<span>
											{intl.formatMessage({
												id: 'common.logistics',
												defaultMessage: 'Logistics',
											})}
										</span>
									}>
									<Menu.Item key="venues">
										{intl.formatMessage({
											id: 'common.venue',
											defaultMessage: 'Venue',
										})}
									</Menu.Item>
									<Menu.Item key="storages">
										{intl.formatMessage({
											id: 'common.storage',
											defaultMessage: 'Storage',
										})}
									</Menu.Item>
									<Menu.Item key="items">
										{intl.formatMessage({
											id: 'common.inventory',
											defaultMessage: 'Inventory',
										})}
									</Menu.Item>
									<Menu.Item key="purchases">
										{intl.formatMessage({
											id: 'common.purchase',
											defaultMessage: 'Purchase',
										})}
									</Menu.Item>
									<Menu.Item key="facilities">
										{intl.formatMessage({
											id: 'common.facilities',
											defaultMessage: 'Facilities',
										})}
									</Menu.Item>
								</SubMenu>
							)}

							{apps.includes('church') && (
								<SubMenu
									key="church"
									title={
										<span>
											{intl.formatMessage({
												id: 'common.church',
												defaultMessage: 'Church',
											})}
										</span>
									}>
									{!isEmpty(organization) &&
										(organization.isMember ||
											organization.isParticipant) && (
											<>
												<Menu.Item key="cellGroups">
													{intl.formatMessage({
														id: 'common.cellGroups',
														defaultMessage:
															'Cell Groups',
													})}
												</Menu.Item>
											</>
										)}
									<Menu.Item key="services">
										{intl.formatMessage({
											id: 'common.services',
											defaultMessage: 'Services',
										})}
									</Menu.Item>
									<Menu.Item key="missions">
										{intl.formatMessage({
											id: 'common.missions',
											defaultMessage: 'Missions',
										})}
									</Menu.Item>
									<Menu.Item key="ministries">
										{intl.formatMessage({
											id: 'common.ministries',
											defaultMessage: 'Ministries',
										})}
									</Menu.Item>
									<Menu.Item key="prayers">
										{intl.formatMessage({
											id: 'common.prayers',
											defaultMessage: 'Prayers',
										})}
									</Menu.Item>
									<Menu.Item key="tithes">
										{intl.formatMessage({
											id: 'common.tithes',
											defaultMessage: 'Tithes',
										})}
									</Menu.Item>
								</SubMenu>
							)}

							{organization.isAdmin && (
								<SubMenu
									key="setting"
									title={
										<span>
											{intl.formatMessage({
												id: 'common.setting',
												defaultMessage: 'Setting',
											})}
										</span>
									}>
									<Menu.Item key="themes">
										{intl.formatMessage({
											id: 'common.theme',
											defaultMessage: 'Theme',
										})}
									</Menu.Item>

									<Menu.Item key="preferences">
										{intl.formatMessage({
											id: 'common.preference',
											defaultMessage: 'Preference',
										})}
									</Menu.Item>
								</SubMenu>
							)}
						</Menu>
					);
				}
				if (organization?.menus?.public?.length > 0) {
					return (
						<MenuBuilder
							{...menuProps}
							onClick={handleClick}
							menus={organization.menus.public}
						/>
					);
				}
			}

			return null;
		},
		[auth, organization]
	);

	if (window.matchMedia('(min-width: 635px)').matches) {
		return menus({ mode: 'horizontal' });
	} else {
		return menus({ mode: 'inline', defaultOpenKeys: ['setting'] });
	}
};

const mapStateToProps = ({ auth, organization }) => {
	return {
		auth,
		organization,
	};
};

export default connect(mapStateToProps, {})(LeftMenu);
