import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Container,
	SubTitle,
	Text,
	Title,
	View,
} from '@common/components';
import { Col, Row, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ThemeContext } from 'styled-components';

const { Content } = Layout;

const Landing = ({ location }) => {
	const theme = useContext(ThemeContext);
	useEffect(() => {
		const fetchData = async () => {};

		fetchData();
	}, []);

	return (
		<Content className="view">
			<Helmet>
				<title>Foreward Network | Landing</title>
			</Helmet>
			<Container>
				<View>
					<Box mb={50}>
						<Row gutter={24} justify="space-around" align="middle">
							<Col md={12} mx={10}>
								<Title size="lg">
									Welcome to Foreward Network, the ever
									growing platform for your daily needs
								</Title>
							</Col>
							<Col md={12}>
								<Box maxWidth={300}>
									<Text>
										Your needs grows over time, so you would
										need a platform that grows with you to
										cater all of your needs. No need to
										register to other platforms to fill the
										void
									</Text>
								</Box>
							</Col>
						</Row>
					</Box>

					<Row gutter={24}>
						<Col md={8}>
							<Box mb={50} mx={10}>
								<SubTitle
									fontSize={30}
									color={theme.base.primary}>
									Journal
								</SubTitle>
								Record your daily life and keep it as part of
								your ever growing memories to reflect on
							</Box>
						</Col>
						<Col md={8}>
							<Box mb={50} mx={10}>
								<SubTitle
									fontSize={30}
									color={theme.base.primary}>
									Inventory
								</SubTitle>
								Keep track of your items, ingredients, assets to
								know how many you have in stock, where you
								bought them, where you store them, etc
							</Box>
						</Col>
						<Col md={8}>
							<Box mb={50} mx={10}>
								<SubTitle
									fontSize={30}
									color={theme.base.primary}>
									Events
								</SubTitle>
								Create events for others to join and manage
							</Box>
						</Col>
						<Col md={8}>
							<Box mb={50} mx={10}>
								<SubTitle
									fontSize={30}
									color={theme.base.primary}>
									Reviews
								</SubTitle>
								Don't forget your experience of your meal that
								you had, a place that you visited, a game that
								you played and share your reviews to your
								connections. There is no better trusted reviews
								than from the people that you know
							</Box>
						</Col>
						<Col md={8}>
							<Box mb={50} mx={10}>
								<SubTitle
									fontSize={30}
									color={theme.base.primary}>
									Tasks
								</SubTitle>
								Organize your tasks so that yo won't miss on
								doing anything
							</Box>
						</Col>
						<Col md={8}>
							<Box mb={50} mx={10}>
								<SubTitle
									fontSize={30}
									color={theme.base.primary}>
									More coming...
								</SubTitle>
								New modules will be constantly created depending
								on demand
							</Box>
						</Col>
					</Row>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ site, events, organizations }) => {
	return { site, events, organizations };
};

export default connect(
	mapStateToProps,
	{}
)(Landing);
